<template>
  <article
      class="css-animation-sequence"
      v-on:wheel="onWheel"
      :class="[ currentState, arrivingClass ]"
      :style="{ display: displayStyle }"
  >

    <div
        class="box"
        ref="box"
    >
      <h1 class="first-title" :class="{ visible: currentStep === 0 }">
        Contrairement aux idées reçues, le VIH est toujours présent en France.
        <div class="scroll-incite" :class="{ visible: !isTransitioning }">
          <ScrollIncitator />
        </div>
      </h1>
      <h1 class="second-title" :class="{ visible: currentStep === 1 }">
        Nous devons rester vigilants afin de limiter sa propagation.
        <div class="scroll-incite" :class="{ visible: !isTransitioning }">
          <ScrollIncitator />
        </div>
      </h1>

      <svg :class="'step' + currentStep" class='ronce1HG ronce' viewBox="0 0 1393 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1383.04 -226.778C1383.04 -226.778 492.956 510.501 -236.337 -118.684" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M965.028 39.8609C964.428 42.6582 961.93 42.7581 960.531 42.7581C963.429 41.0598 961.63 38.5622 960.431 37.4633C959.232 36.3644 956.734 36.0646 953.836 35.3653C950.638 34.5661 950.638 30.7698 949.439 28.4721L959.732 24.1763C961.43 25.475 965.628 27.7728 964.029 30.57C961.93 34.2664 966.327 32.9677 965.028 39.8609Z" fill="white"/>
        <path d="M911.365 18.5817C911.965 15.7844 914.463 15.7844 915.762 15.7844C912.964 17.3829 914.663 19.9803 915.762 21.0792C916.861 22.1782 919.46 22.4779 922.158 23.2771C925.256 24.1762 925.256 27.8726 926.455 30.2703L916.162 34.3663C914.463 33.0675 910.366 30.57 912.065 27.7727C914.263 24.2761 909.866 25.475 911.365 18.5817Z" fill="white"/>
        <path d="M848.809 83.6183C848.009 86.3157 845.511 86.2158 844.112 86.2158C847.11 84.7173 845.511 82.0199 844.412 80.921C843.313 79.7222 840.814 79.3225 838.016 78.4234C834.918 77.4244 835.118 73.6281 834.119 71.2305L844.712 67.7339C846.31 69.1325 850.408 71.73 848.509 74.4273C846.211 77.7241 850.707 76.7251 848.809 83.6183Z" fill="white"/>
        <path d="M796.945 58.2431C797.744 55.5457 800.243 55.6456 801.542 55.7455C798.644 57.1442 800.143 59.8415 801.142 61.0403C802.141 62.2392 804.64 62.7387 807.338 63.7377C810.436 64.8366 810.136 68.533 811.135 71.0306L800.542 74.3274C798.943 72.9287 795.046 70.1315 796.945 67.534C799.443 64.2372 794.946 65.0364 796.945 58.2431Z" fill="white"/>
        <path d="M729.492 118.184C728.492 120.882 725.994 120.582 724.595 120.382C727.693 119.183 726.294 116.386 725.395 115.187C724.395 113.889 721.897 113.289 719.199 112.09C716.201 110.792 716.701 107.095 715.901 104.598L726.694 102C728.193 103.499 731.99 106.496 729.891 108.993C727.293 112.09 731.89 111.491 729.492 118.184Z" fill="white"/>
        <path d="M679.826 88.5133C680.826 85.9159 683.324 86.2156 684.623 86.5153C681.625 87.7141 682.924 90.5114 683.824 91.7102C684.723 93.009 687.221 93.7083 689.819 94.9071C692.817 96.3057 692.218 100.002 692.917 102.5L682.125 104.797C680.726 103.299 677.028 100.202 679.127 97.7044C681.825 94.7073 677.328 95.1069 679.826 88.5133Z" fill="white"/>
        <path d="M607.277 142.161C606.077 144.759 603.579 144.159 602.18 143.959C605.378 142.96 604.279 140.063 603.479 138.764C602.58 137.366 600.182 136.567 597.583 135.168C594.685 133.569 595.585 129.973 594.985 127.376C598.683 126.876 602.28 126.277 605.978 125.777C607.277 127.376 610.874 130.772 608.576 133.07C605.778 135.967 610.374 135.767 607.277 142.161Z" fill="white"/>
        <path d="M560.709 108.094C562.008 105.597 564.406 106.196 565.706 106.496C562.608 107.395 563.607 110.292 564.406 111.591C565.206 112.99 567.604 113.889 570.003 115.387C572.801 116.986 571.901 120.682 572.401 123.18C568.703 123.579 565.106 124.079 561.408 124.478C560.109 122.78 556.812 119.383 559.11 117.085C562.108 114.388 557.611 114.388 560.709 108.094Z" fill="white"/>
        <path d="M483.263 154.249C481.764 156.647 479.365 155.948 477.966 155.448C481.264 154.849 480.465 151.852 479.765 150.453C479.066 148.954 476.667 147.855 474.269 146.257C471.571 144.359 472.87 140.862 472.57 138.265C476.268 138.165 479.965 138.065 483.662 137.865C484.862 139.564 487.959 143.26 485.461 145.358C482.263 147.855 486.96 148.155 483.263 154.249Z" fill="white"/>
        <path d="M440.492 115.487C441.991 113.089 444.29 113.989 445.589 114.488C442.391 114.988 443.091 117.985 443.69 119.383C444.39 120.882 446.588 122.081 448.887 123.779C451.485 125.677 450.186 129.174 450.486 131.771L444.989 131.871H439.493C438.394 130.073 435.396 126.277 437.994 124.279C441.192 121.881 436.695 121.381 440.492 115.487Z" fill="white"/>
        <path d="M358.35 152.85C356.551 155.048 354.252 154.049 352.953 153.45C356.351 153.25 355.851 150.153 355.252 148.655C354.652 147.156 352.454 145.757 350.355 143.859C347.857 141.661 349.556 138.365 349.556 135.667C353.253 135.967 356.951 136.267 360.648 136.466C361.647 138.365 364.345 142.361 361.547 144.159C358.15 146.457 362.747 147.156 358.35 152.85Z" fill="white"/>
        <path d="M320.376 109.393C322.175 107.195 324.373 108.394 325.572 108.993C322.375 109.093 322.674 112.19 323.174 113.689C323.674 115.187 325.772 116.686 327.871 118.584C330.269 120.782 328.57 124.079 328.47 126.776C324.773 126.476 321.175 125.977 317.478 125.577C316.579 123.679 314.08 119.583 316.878 117.885C320.276 115.787 315.879 114.788 320.376 109.393Z" fill="white"/>
        <path d="M234.436 136.666C232.437 138.664 230.239 137.366 229.039 136.566C232.437 136.766 232.337 133.669 231.937 132.171C231.538 130.572 229.539 128.974 227.64 126.776C225.442 124.278 227.54 121.181 227.84 118.584C231.438 119.283 235.135 120.083 238.733 120.782C239.432 122.78 241.731 127.076 238.733 128.474C234.935 130.273 239.432 131.571 234.436 136.666Z" fill="white"/>
        <path d="M201.958 88.9129C203.957 87.0148 206.055 88.4134 207.155 89.1127C203.957 88.9129 203.857 91.91 204.157 93.5084C204.457 95.1068 206.355 96.8052 208.154 99.003C210.253 101.501 208.154 104.598 207.754 107.195L202.358 105.996L196.962 104.697C196.362 102.699 194.364 98.3037 197.361 97.005C201.159 95.3067 196.862 93.7082 201.958 88.9129Z" fill="white"/>
        <path d="M113.32 105.197C111.021 106.895 109.023 105.397 107.924 104.498C111.221 105.097 111.521 102 111.421 100.502C111.221 98.9033 109.423 97.105 107.824 94.6075C105.925 91.9101 108.423 89.0129 109.023 86.5154C112.52 87.7142 116.018 88.8131 119.615 90.012C120.115 92.1099 121.714 96.6055 118.616 97.7044C114.719 98.9033 118.916 100.801 113.32 105.197Z" fill="white"/>
        <path d="M87.238 53.7474C89.5364 52.049 91.3352 53.6475 92.3345 54.5466C89.1367 53.9472 88.737 56.9442 88.8369 58.4428C88.9368 60.0412 90.6356 61.9394 92.1346 64.337C93.8334 67.0344 91.4351 69.8316 90.7356 72.4291L80.3428 68.6328C79.9431 66.6348 78.5441 61.9394 81.7418 61.0402C85.5392 59.9413 81.542 57.9433 87.238 53.7474Z" fill="white"/>
        <path d="M-2.79945 58.7425C-5.19778 60.1412 -6.99653 58.3429 -7.99584 57.3439C-4.79806 58.3429 -4.09855 55.3459 -4.09855 53.7474C-4.09855 52.149 -5.59751 50.051 -6.8966 47.4536C-8.39556 44.5564 -5.59751 42.0588 -4.69813 39.5613L5.29492 44.4565C5.49478 46.5544 6.59402 51.2499 3.39624 51.9492C-0.70091 52.6485 3.29631 55.146 -2.79945 58.7425Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce2HD ronce" viewBox="0 0 355 1023" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M752.724 1023C752.724 1023 -211.606 467.743 74.0951 -217.088" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M345.407 729.487C342.609 729.986 341.509 727.688 340.91 726.39C343.608 728.388 345.307 725.79 345.806 724.292C346.406 722.793 345.606 720.296 345.207 717.398C344.707 714.102 348.205 712.703 349.904 710.705C352.502 713.402 355.2 716 357.798 718.697C357.298 720.795 356.699 725.59 353.501 725.091C349.404 724.492 352.402 728.088 345.407 729.487Z" fill="white"/>
        <path d="M344.407 671.344C347.205 670.844 348.205 673.142 348.704 674.441C346.106 672.442 344.407 675.04 343.808 676.439C343.208 677.937 343.908 680.435 344.207 683.332C344.607 686.629 341.11 688.027 339.411 689.925L331.616 681.833C332.116 679.835 332.815 675.04 336.113 675.539C340.21 676.239 337.412 672.542 344.407 671.344Z" fill="white"/>
        <path d="M259.766 637.477C256.968 637.776 256.069 635.379 255.569 634.08C258.167 636.278 259.966 633.78 260.566 632.382C261.265 630.883 260.665 628.386 260.466 625.488C260.266 622.192 263.763 620.993 265.662 619.195L272.957 627.686C272.357 629.684 271.358 634.48 268.16 633.78C264.163 632.781 266.861 636.677 259.766 637.477Z" fill="white"/>
        <path d="M263.164 579.533C265.962 579.234 266.761 581.631 267.261 582.93C264.763 580.732 262.864 583.13 262.164 584.529C261.465 585.927 261.965 588.525 262.065 591.322C262.264 594.619 258.667 595.718 256.768 597.616C254.37 594.719 251.972 591.821 249.673 588.924C250.373 586.926 251.472 582.231 254.67 583.03C258.667 584.029 256.169 580.133 263.164 579.533Z" fill="white"/>
        <path d="M181.72 538.673C178.822 538.773 178.123 536.276 177.823 534.877C180.122 537.375 182.22 534.977 183.02 533.678C183.819 532.279 183.519 529.682 183.519 526.785C183.619 523.488 187.217 522.589 189.215 520.891C191.414 523.988 193.512 526.985 195.711 530.082C194.911 531.98 193.612 536.675 190.414 535.676C186.517 534.477 188.915 538.473 181.72 538.673Z" fill="white"/>
        <path d="M190.414 481.229C193.212 481.229 193.812 483.627 194.112 485.026C191.913 482.628 189.715 484.826 188.915 486.125C188.016 487.523 188.316 490.021 188.116 492.918C187.916 496.215 184.319 497.014 182.22 498.612L176.024 489.322C176.924 487.423 178.423 482.828 181.521 483.927C185.518 485.325 183.319 481.329 190.414 481.229Z" fill="white"/>
        <path d="M113.468 432.477C110.67 432.277 110.17 429.68 109.97 428.281C112.069 430.978 114.367 428.881 115.267 427.582C116.266 426.283 116.166 423.686 116.466 420.788C116.866 417.492 120.663 417.092 122.761 415.594L128.258 425.384C127.258 427.282 125.36 431.778 122.362 430.379C118.664 428.781 120.563 433.076 113.468 432.477Z" fill="white"/>
        <path d="M128.457 376.432C131.256 376.732 131.555 379.229 131.755 380.528C129.856 377.93 127.458 379.928 126.459 381.027C125.46 382.326 125.46 384.824 124.96 387.721C124.46 390.918 120.663 391.317 118.464 392.716C116.765 389.419 114.967 386.122 113.268 382.826C114.367 381.027 116.466 376.632 119.364 378.13C123.061 379.928 121.462 375.633 128.457 376.432Z" fill="white"/>
        <path d="M58.0064 318.588C55.2084 317.989 55.1084 315.391 55.1084 313.993C56.8072 316.99 59.4053 315.192 60.4047 314.093C61.5039 312.894 61.8038 310.296 62.5033 307.499C63.4027 304.302 67.1001 304.302 69.4984 303.203L71.4971 308.498L73.5956 313.693C72.2965 315.391 69.9981 319.687 67.1001 317.889C63.7024 315.591 65.0016 320.087 58.0064 318.588Z" fill="white"/>
        <path d="M80.4908 265.041C83.189 265.74 83.1889 268.238 83.089 269.536C81.59 266.639 78.8919 268.338 77.7926 269.337C76.5935 270.435 76.1937 272.933 75.2944 275.73C74.395 278.827 70.5977 278.727 68.1993 279.826C66.9002 276.33 65.7011 272.833 64.502 269.237C65.801 267.638 68.4991 263.542 71.1972 265.44C74.6948 267.738 73.5956 263.242 80.4908 265.041Z" fill="white"/>
        <path d="M19.4331 197.407C16.735 196.308 17.1348 193.711 17.3346 192.312C18.5338 195.509 21.3319 194.11 22.5311 193.211C23.8302 192.212 24.5296 189.715 25.6289 187.017C26.928 184.02 30.7253 184.62 33.2235 183.82L35.7218 194.81C34.2228 196.308 31.225 200.104 28.6268 198.006C25.5289 195.309 26.1285 200.005 19.4331 197.407Z" fill="white"/>
        <path d="M50.2118 148.155C52.81 149.254 52.3104 151.652 52.1105 153.05C51.0113 149.953 48.2132 151.152 46.914 152.051C45.6149 152.95 44.8156 155.348 43.5165 157.946C42.0175 160.843 38.4199 160.143 35.8217 160.843C35.2222 157.146 34.4227 153.55 33.8231 149.853C35.422 148.455 38.7198 144.958 41.1181 147.156C44.0161 149.853 43.8162 145.358 50.2118 148.155Z" fill="white"/>
        <path d="M1.94527 71.0305C-0.453062 69.532 0.246502 67.0344 0.746156 65.6358C1.34574 69.0325 4.34366 68.1333 5.74269 67.434C7.24165 66.7347 8.24091 64.337 9.93973 61.8395C11.8384 59.1421 15.336 60.4409 17.9342 60.1411C17.9342 63.9374 18.234 67.6338 18.4338 71.4301C16.6351 72.6289 13.0376 75.8258 10.939 73.2284C8.34084 70.0315 8.04105 74.8268 1.94527 71.0305Z" fill="white"/>
        <path d="M41.3179 28.2723C43.6163 29.8707 42.717 32.1685 42.2173 33.3673C41.8176 30.1705 38.7197 30.7699 37.3207 31.3693C35.8217 31.9687 34.6226 34.1665 32.8238 36.4643C30.8252 39.0618 27.3277 37.6631 24.7295 37.8629V32.2684L24.8294 26.6739C26.6281 25.5749 30.5254 22.7777 32.4241 25.3751C34.8224 28.7718 35.422 24.2762 41.3179 28.2723Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce3HD ronce" viewBox="0 0 750 433" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5139 -435.574C10.5139 -435.574 204.579 420.389 1108.75 410.998" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M241.753 -13.9863C244.551 -14.1861 245.251 -11.8884 245.65 -10.5896C243.352 -12.7875 241.353 -10.3898 240.554 -9.0911C239.755 -7.69246 240.154 -5.19491 240.154 -2.39764C240.154 0.799236 236.557 1.89816 234.658 3.69641L227.963 -4.8952C228.762 -6.79334 229.961 -11.4888 233.059 -10.5896C237.056 -9.59061 234.758 -13.3869 241.753 -13.9863Z" fill="white"/>
        <path d="M236.257 43.1578C233.459 43.4575 232.66 41.0598 232.16 39.7611C234.558 41.9589 236.457 39.5613 237.156 38.1626C237.856 36.764 237.356 34.1665 237.256 31.2694C237.156 27.9726 240.754 26.8737 242.653 25.0754L249.548 33.5671C248.848 35.5652 247.849 40.2606 244.651 39.4614C240.754 38.5623 243.352 42.3585 236.257 43.1578Z" fill="white"/>
        <path d="M319.099 76.2255C321.897 75.726 322.797 77.9238 323.297 79.1226C320.698 77.2245 318.999 79.8219 318.4 81.2206C317.8 82.7191 318.4 85.1168 318.8 87.914C319.199 91.1109 315.702 92.6094 314.003 94.5076C311.505 91.9101 308.907 89.3127 306.408 86.7152C307.008 84.7172 307.807 79.9218 310.905 80.4214C314.902 81.1207 312.204 77.5242 319.099 76.2255Z" fill="white"/>
        <path d="M319.899 133.47C317.101 134.069 316.002 131.771 315.402 130.572C318.1 132.47 319.699 129.873 320.199 128.375C320.798 126.876 319.999 124.378 319.599 121.581C319.099 118.384 322.597 116.886 324.196 114.888L332.09 122.48C331.591 124.478 331.091 129.274 327.893 128.974C323.896 128.375 326.894 131.971 319.899 133.47Z" fill="white"/>
        <path d="M405.939 157.046C408.637 156.147 409.836 158.345 410.436 159.544C407.738 157.946 406.239 160.643 405.839 162.142C405.339 163.64 406.339 166.038 406.938 168.835C407.638 171.932 404.44 173.83 402.941 176.028L394.547 169.135C394.847 167.037 395.047 162.341 398.344 162.441C402.342 162.341 399.244 159.144 405.939 157.046Z" fill="white"/>
        <path d="M413.234 213.891C410.536 214.79 409.237 212.592 408.537 211.493C411.435 213.092 412.734 210.294 413.134 208.796C413.534 207.197 412.435 204.9 411.735 202.102C410.936 198.906 414.133 197.107 415.533 194.909L424.226 201.603C424.027 203.701 424.027 208.496 420.729 208.496C416.632 208.396 419.929 211.593 413.234 213.891Z" fill="white"/>
        <path d="M501.473 227.178C503.971 225.979 505.47 227.977 506.269 229.076C503.371 227.777 502.272 230.674 502.072 232.173C501.773 233.771 502.972 235.969 503.971 238.667C505.07 241.664 502.072 243.961 500.873 246.259L491.68 240.365C491.779 238.267 491.38 233.472 494.578 233.272C498.475 232.972 495.077 230.075 501.473 227.178Z" fill="white"/>
        <path d="M515.463 282.823C512.965 284.022 511.366 282.024 510.467 280.925C513.564 282.224 514.464 279.227 514.664 277.728C514.863 276.13 513.564 273.932 512.465 271.235C511.266 268.238 514.264 265.94 515.363 263.542L524.757 269.237C524.757 271.335 525.356 276.13 522.158 276.53C518.161 276.929 521.859 279.726 515.463 282.823Z" fill="white"/>
        <path d="M604.701 285.321C607.099 283.823 608.698 285.621 609.698 286.62C606.7 285.721 605.9 288.718 605.9 290.216C605.8 291.815 607.299 293.913 608.598 296.41C609.997 299.307 607.299 301.805 606.4 304.303L596.607 299.607C596.407 297.509 595.507 292.814 598.705 292.214C602.502 291.315 598.705 289.017 604.701 285.321Z" fill="white"/>
        <path d="M625.387 338.769C622.988 340.267 621.189 338.469 620.19 337.57C623.388 338.469 623.988 335.372 623.988 333.874C623.988 332.275 622.389 330.277 620.99 327.68C619.391 324.783 622.089 322.185 622.988 319.688L632.981 324.183C633.281 326.281 634.38 330.976 631.283 331.676C627.385 332.675 631.383 334.973 625.387 338.769Z" fill="white"/>
        <path d="M714.225 330.677C716.423 328.978 718.322 330.477 719.321 331.376C716.124 330.876 715.824 333.874 715.924 335.472C716.024 337.07 717.722 338.869 719.321 341.266C721.12 343.964 718.722 346.761 718.122 349.358L707.829 345.962C707.43 343.964 705.931 339.368 709.028 338.369C712.726 336.971 708.629 335.072 714.225 330.677Z" fill="white"/>
        <path d="M741.106 381.227C738.908 383.026 736.909 381.527 735.81 380.628C739.108 381.127 739.307 378.03 739.108 376.532C738.908 374.933 737.109 373.135 735.41 370.837C733.512 368.24 735.91 365.343 736.41 362.745L746.902 365.842C747.402 367.84 749.101 372.336 746.103 373.535C742.305 374.933 746.602 376.732 741.106 381.227Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce4HG ronce" viewBox="0 0 343 488" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M322.375 -378.63C322.375 -378.63 231.438 477.333 -192.268 467.943" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M259.718 4.49555C261.417 6.79331 259.818 8.89126 258.919 9.99018C259.518 6.5935 256.42 6.2938 254.822 6.4936C253.223 6.6934 251.324 8.59155 248.926 10.2899C246.227 12.2879 243.33 9.79038 240.831 9.19096L244.329 -1.79829C246.427 -2.29781 250.924 -4.09605 252.023 -0.899173C253.422 3.19682 255.321 -1.19888 259.718 4.49555Z" fill="white"/>
        <path d="M208.154 33.0675C206.455 30.7698 208.054 28.7717 208.953 27.6728C208.354 30.9696 211.452 31.3692 212.951 31.1694C214.55 30.9696 216.448 29.1714 218.847 27.5729C221.545 25.6748 224.443 28.1723 226.941 28.7717L223.144 39.6611C221.045 40.1606 216.448 41.759 215.449 38.5622C214.45 34.4662 212.351 38.762 208.154 33.0675Z" fill="white"/>
        <path d="M215.449 125.877C216.948 128.275 215.249 130.273 214.25 131.372C215.149 127.975 212.051 127.475 210.552 127.575C208.953 127.675 206.855 129.373 204.357 130.872C201.459 132.67 198.86 129.873 196.362 129.074L200.859 118.384C202.958 117.985 207.654 116.586 208.454 119.883C209.253 123.979 211.552 119.783 215.449 125.877Z" fill="white"/>
        <path d="M161.486 149.554C159.987 147.156 161.786 145.258 162.785 144.259C161.886 147.456 164.884 148.155 166.383 148.155C167.982 148.155 170.08 146.557 172.579 145.158C175.477 143.559 178.075 146.257 180.573 147.156C178.974 150.653 177.475 154.149 175.776 157.646C173.678 157.945 168.881 159.044 168.282 155.847C167.482 151.652 165.084 155.648 161.486 149.554Z" fill="white"/>
        <path d="M158.588 242.563C159.788 245.16 157.789 246.959 156.69 247.858C157.989 244.561 154.991 243.762 153.492 243.562C151.893 243.462 149.595 244.861 146.897 246.159C143.799 247.558 141.5 244.561 139.202 243.362L144.998 233.372C147.096 233.272 151.993 232.473 152.293 235.77C152.692 240.065 155.491 236.069 158.588 242.563Z" fill="white"/>
        <path d="M101.928 259.047C100.829 256.449 102.827 254.951 103.926 254.052C102.627 257.149 105.525 258.148 107.024 258.348C108.623 258.547 110.822 257.249 113.62 256.25C116.718 255.051 118.916 258.048 121.214 259.247C119.216 262.444 117.117 265.74 115.019 268.937C112.92 268.937 108.024 269.337 107.824 266.04C107.724 261.944 104.726 265.54 101.928 259.047Z" fill="white"/>
        <path d="M83.9403 350.357C84.6398 353.155 82.3414 354.553 81.0423 355.253C83.0409 352.256 80.2429 350.857 78.7439 350.457C77.145 350.058 74.6467 351.057 71.7488 351.756C68.4511 352.555 66.8522 349.159 64.6537 347.56C67.2519 344.663 69.7501 341.766 72.2484 338.769C74.347 339.069 79.2435 339.168 79.0437 342.465C78.644 346.761 82.1415 343.364 83.9403 350.357Z" fill="white"/>
        <path d="M25.281 355.053C24.7814 352.356 26.9799 351.257 28.279 350.657C26.3803 353.355 28.9785 354.953 30.3775 355.552C31.8765 356.152 34.3747 355.353 37.1728 354.953C40.4705 354.454 41.9695 357.85 43.9681 359.549C41.2699 362.246 38.6717 365.043 35.8737 367.641C33.7751 367.141 28.9785 366.542 29.5781 363.245C30.1776 359.149 26.5801 361.946 25.281 355.053Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce5HG ronce" viewBox="0 0 360 881" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M342.76 967.915C342.76 967.915 257.32 11.4499 -140.903 21.9397" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M307.385 846.933C304.787 845.734 305.386 843.337 305.686 842.038C306.686 845.135 309.583 844.036 310.883 843.237C312.282 842.338 313.081 840.04 314.48 837.542C316.079 834.745 319.676 835.544 322.275 834.945L323.874 845.734C322.275 847.033 318.877 850.53 316.579 848.232C313.581 845.335 313.781 849.93 307.385 846.933Z" fill="white"/>
        <path d="M340.362 799.979C342.96 801.178 342.461 803.576 342.161 804.975C341.161 801.878 338.264 802.976 336.964 803.876C335.565 804.775 334.766 807.172 333.467 809.67C331.968 812.567 328.27 811.768 325.772 812.367L323.973 801.578C325.572 800.179 328.87 796.683 331.168 798.98C334.166 801.678 333.867 797.082 340.362 799.979Z" fill="white"/>
        <path d="M287.099 728.149C284.501 727.05 284.901 724.653 285.2 723.354C286.3 726.451 289.098 725.152 290.397 724.353C291.696 723.454 292.495 721.056 293.794 718.459C295.194 715.561 298.891 716.261 301.389 715.562L303.488 726.351C301.889 727.75 298.691 731.346 296.293 729.148C293.195 726.351 293.595 730.847 287.099 728.149Z" fill="white"/>
        <path d="M318.078 679.896C320.676 680.895 320.276 683.393 320.076 684.792C318.977 681.695 316.079 682.993 314.88 683.893C313.581 684.792 312.781 687.289 311.582 689.887C310.183 692.784 306.486 692.185 303.987 692.884L301.789 682.094C303.288 680.696 306.486 676.999 308.884 679.197C311.982 681.795 311.482 677.299 318.078 679.896Z" fill="white"/>
        <path d="M261.717 610.465C259.118 609.565 259.418 607.068 259.518 605.769C260.717 608.766 263.515 607.368 264.715 606.469C266.014 605.47 266.613 603.072 267.812 600.474C269.112 597.477 272.809 597.977 275.307 597.178L277.905 607.867C276.406 609.366 273.409 613.062 270.91 610.964C267.713 608.367 268.312 612.862 261.717 610.465Z" fill="white"/>
        <path d="M290.397 560.813C293.095 561.712 292.795 564.21 292.695 565.609C291.396 562.611 288.698 564.01 287.499 564.909C286.2 565.908 285.6 568.406 284.501 571.003C283.202 574 279.504 573.501 277.006 574.4L274.308 563.81C275.807 562.312 278.705 558.516 281.303 560.514C284.401 563.011 283.701 558.516 290.397 560.813Z" fill="white"/>
        <path d="M230.438 494.478C227.74 493.679 227.94 491.281 228.04 489.882C229.439 492.78 232.137 491.281 233.336 490.282C234.536 489.283 235.035 486.785 236.134 484.088C237.234 481.091 241.031 481.391 243.429 480.392L246.627 490.881C245.228 492.38 242.43 496.276 239.732 494.378C236.434 491.98 237.234 496.476 230.438 494.478Z" fill="white"/>
        <path d="M256.221 443.128C258.919 443.927 258.819 446.425 258.719 447.824C257.22 444.826 254.622 446.425 253.422 447.424C252.223 448.523 251.724 451.02 250.824 453.718C249.725 456.815 246.028 456.515 243.529 457.514L240.132 447.024C241.531 445.426 244.229 441.43 246.927 443.328C250.325 445.726 249.425 441.23 256.221 443.128Z" fill="white"/>
        <path d="M191.965 380.989C189.267 380.39 189.267 377.992 189.267 376.593C190.866 379.391 193.464 377.692 194.563 376.593C195.763 375.494 196.062 372.997 196.862 370.2C197.761 367.103 201.459 367.103 203.857 365.904C205.156 369.3 206.555 372.697 207.754 376.094C206.455 377.792 203.957 381.788 201.159 380.09C197.761 378.092 198.86 382.488 191.965 380.989Z" fill="white"/>
        <path d="M213.65 327.841C216.448 328.34 216.548 330.938 216.548 332.336C214.849 329.539 212.351 331.337 211.252 332.436C210.153 333.635 209.853 336.133 209.153 338.93C208.354 342.127 204.556 342.127 202.258 343.326C200.859 339.929 199.46 336.532 198.061 333.136C199.26 331.437 201.658 327.241 204.457 328.84C207.954 330.938 206.655 326.542 213.65 327.841Z" fill="white"/>
        <path d="M143.899 271.995C141.101 271.696 140.901 269.298 140.701 267.999C142.6 270.597 144.998 268.599 145.897 267.4C146.897 266.201 146.996 263.604 147.496 260.806C148.096 257.61 151.793 257.21 153.992 255.811C155.69 259.008 157.289 262.305 158.988 265.502C157.889 267.3 155.79 271.596 152.892 270.197C149.295 268.499 150.894 272.695 143.899 271.995Z" fill="white"/>
        <path d="M159.288 216.65C162.086 216.849 162.486 219.347 162.785 220.746C160.687 218.148 158.488 220.246 157.489 221.445C156.49 222.744 156.59 225.341 156.19 228.238C155.79 231.435 151.993 231.935 149.894 233.433C148.096 230.236 146.297 227.039 144.498 223.843C145.498 222.044 147.296 217.549 150.294 218.847C154.092 220.446 152.193 216.25 159.288 216.65Z" fill="white"/>
        <path d="M82.4413 171.494C79.6433 171.693 79.0437 169.396 78.644 168.097C80.9424 170.295 82.941 167.897 83.7404 166.598C84.5399 165.2 84.1401 162.802 84.2401 159.905C84.2401 156.708 87.8376 155.609 89.7363 153.911C91.9347 156.808 94.1332 159.705 96.3317 162.602C95.5322 164.501 94.1332 169.096 91.0353 168.197C87.1381 167.198 89.3365 170.994 82.4413 171.494Z" fill="white"/>
        <path d="M87.4379 114.25C90.2359 113.95 91.2352 116.347 91.7349 117.646C89.1367 115.448 87.3379 117.946 86.7383 119.345C86.0388 120.843 86.6384 123.341 86.8383 126.238C87.0381 129.535 83.4406 130.733 81.6419 132.532C79.2435 129.734 76.8452 126.937 74.4469 124.14C75.0465 122.142 75.9458 117.347 79.1436 118.046C83.0409 118.945 80.3428 115.149 87.4379 114.25Z" fill="white"/>
        <path d="M2.09715 89.0742C-0.501044 90.0732 -1.70021 88.1751 -2.39972 86.9762C0.298401 88.3749 1.5975 85.5776 1.89729 84.0791C2.29701 82.4806 1.29771 80.2828 0.398331 77.5854C-0.501044 74.5883 2.49687 72.4904 3.8959 70.1926C6.89382 72.1907 9.79181 74.3885 12.6898 76.4865C12.4899 78.5844 12.4899 83.2798 9.29215 83.3798C5.39486 83.5796 8.59264 86.4767 2.09715 89.0742Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce6BG ronce" viewBox="0 0 633 533" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-101.931 20.501C-101.931 20.501 609.575 128.495 612.573 694.842" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M31.2769 31.8898C33.5753 30.0915 35.6738 31.69 36.873 32.4892C33.4753 31.9897 33.2755 35.0866 33.4753 36.5851C33.6752 38.1836 35.6738 40.0817 37.4726 42.3795C39.4712 45.0768 37.0728 47.974 36.5732 50.5714L25.4809 47.2747C24.8813 45.1767 22.9826 40.6811 26.1804 39.5822C30.1776 38.1836 25.6808 36.3854 31.2769 31.8898Z" fill="white"/>
        <path d="M60.5565 83.0396C58.2581 84.7379 56.2595 83.1395 55.1603 82.2404C58.458 82.8398 58.7578 79.7428 58.6578 78.2443C58.458 76.6459 56.6592 74.7478 54.9604 72.3501C53.0617 69.6528 55.4601 66.7556 56.0596 64.2581C59.6571 65.4569 63.3546 66.6557 66.9521 67.8546C67.4517 69.9525 69.1505 74.5479 65.9528 75.5469C61.9555 76.7458 66.2526 78.7438 60.5565 83.0396Z" fill="white"/>
        <path d="M153.292 75.0475C155.69 73.549 157.689 75.2474 158.788 76.2464C155.391 75.3473 154.891 78.4443 154.991 79.9428C155.091 81.5413 156.79 83.6392 158.389 86.1368C160.187 89.034 157.489 91.6315 156.59 94.129L145.897 89.7333C145.497 87.6353 144.098 82.9399 147.396 82.1407C151.493 81.2416 147.196 78.9438 153.292 75.0475Z" fill="white"/>
        <path d="M177.076 128.895C174.677 130.394 172.779 128.595 171.779 127.596C174.977 128.495 175.677 125.498 175.677 123.9C175.677 122.302 174.078 120.204 172.579 117.706C170.98 114.809 173.678 112.211 174.477 109.714L184.97 114.409C185.27 116.507 186.369 121.302 183.171 121.902C179.174 122.901 183.171 125.199 177.076 128.895Z" fill="white"/>
        <path d="M270.111 131.392C272.709 130.194 274.408 132.092 275.407 133.291C272.11 131.992 271.31 134.989 271.21 136.587C271.11 138.186 272.609 140.384 273.808 143.081C275.207 146.178 272.209 148.476 271.11 150.873L261.017 145.179C260.917 143.081 260.018 138.186 263.416 137.786C267.613 137.287 263.615 134.489 270.111 131.392Z" fill="white"/>
        <path d="M287.199 187.637C284.601 188.836 283.002 186.838 282.202 185.739C285.3 187.038 286.3 184.141 286.499 182.542C286.699 180.944 285.4 178.646 284.301 175.949C283.002 172.852 286 170.654 287.199 168.256L296.992 174.25C296.992 176.348 297.592 181.244 294.294 181.443C289.997 181.943 293.794 184.74 287.199 187.637Z" fill="white"/>
        <path d="M379.135 202.423C380.534 202.023 381.533 202.323 382.433 202.922C383.232 203.522 383.832 204.421 384.232 205.02C381.234 203.222 379.935 206.119 379.635 207.618C379.235 209.216 380.434 211.614 381.234 214.511C382.133 217.808 378.835 219.606 377.436 221.804C374.438 219.406 371.44 217.009 368.343 214.711C368.542 212.613 368.343 207.718 371.74 207.818C375.737 207.918 372.14 204.621 379.135 202.423Z" fill="white"/>
        <path d="M387.729 260.666C385.031 261.465 383.732 259.267 383.032 258.068C385.83 259.767 387.329 257.069 387.729 255.571C388.129 254.072 387.229 251.675 386.53 248.778C385.731 245.581 389.028 243.783 390.527 241.685C393.425 244.182 396.423 246.58 399.321 249.077C399.021 251.175 398.821 256.07 395.524 255.771C391.427 255.371 394.524 258.868 387.729 260.666Z" fill="white"/>
        <path d="M475.768 290.537C478.666 290.237 479.665 292.635 480.165 294.033C477.467 291.736 475.768 294.333 475.168 295.732C474.569 297.23 475.268 299.828 475.568 302.825C475.968 306.222 472.37 307.42 470.572 309.319L462.877 300.627C463.477 298.529 464.076 293.734 467.474 294.433C471.571 295.332 468.673 291.336 475.768 290.537Z" fill="white"/>
        <path d="M473.569 349.379C470.771 349.579 470.072 347.181 469.572 345.883C472.071 348.081 473.969 345.683 474.669 344.284C475.368 342.886 474.869 340.288 474.769 337.391C474.569 334.094 478.166 332.995 480.065 331.097L483.762 335.493C484.962 336.991 486.161 338.49 487.26 339.988C486.561 341.986 485.361 346.782 482.164 345.883C478.166 344.884 480.565 348.78 473.569 349.379Z" fill="white"/>
        <path d="M553.714 396.234C556.612 396.533 557.112 399.131 557.311 400.629C555.113 397.832 552.914 399.93 552.015 401.229C551.116 402.527 551.216 405.225 550.916 408.222C550.516 411.618 546.819 412.018 544.62 413.517L538.924 403.426C539.923 401.528 541.622 396.933 544.72 398.232C548.617 400.03 546.619 395.534 553.714 396.234Z" fill="white"/>
        <path d="M538.824 453.178C537.425 452.978 536.726 452.279 536.326 451.38C535.926 450.58 535.826 449.581 535.726 448.882C537.625 451.579 540.023 449.681 541.023 448.482C542.022 447.284 542.122 444.686 542.622 441.789C543.121 438.592 546.919 438.193 549.117 436.894L551.715 441.989C552.615 443.687 553.314 445.485 554.213 447.184C553.014 448.982 550.916 453.378 548.018 451.779C544.32 449.781 545.819 454.177 538.824 453.178Z" fill="white"/>
        <path d="M606.377 516.916C607.776 517.415 608.476 518.314 608.776 519.213C609.075 520.212 608.975 521.211 608.975 522.01C607.576 518.714 604.878 520.312 603.679 521.411C602.48 522.51 601.98 525.207 600.881 528.005C599.682 531.201 595.984 530.702 593.486 531.701C592.387 528.005 591.488 524.208 590.288 520.612C591.687 519.013 594.386 514.917 597.084 516.916C600.481 519.513 599.482 514.718 606.377 516.916Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce7BG ronce" viewBox="0 0 1437 219" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-50.966 60.1553C-50.966 60.1553 1324.18 -114.074 1486.17 277.343" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M70.5496 27.1874C71.8487 24.6898 74.247 25.3892 75.5461 25.6889C72.3483 26.4881 73.3476 29.3852 74.0472 30.7839C74.8466 32.1825 77.145 33.1816 79.5433 34.6801C82.2415 36.3785 81.2422 39.975 81.7418 42.5724L70.8494 43.5715C69.6502 41.8731 66.3525 38.3765 68.7508 36.1786C71.7488 33.5812 67.2519 33.4813 70.5496 27.1874Z" fill="white"/>
        <path d="M114.919 63.252C113.62 65.7495 111.221 65.0503 109.922 64.6506C113.02 63.8514 112.121 60.9543 111.421 59.5556C110.622 58.157 108.323 57.158 105.925 55.5595C103.227 53.8612 104.226 50.2647 103.826 47.6672L114.619 46.7681C115.818 48.4664 119.016 52.0629 116.718 54.1609C113.82 56.9581 118.316 57.058 114.919 63.252Z" fill="white"/>
        <path d="M190.966 17.1972C192.365 14.6997 194.663 15.399 196.062 15.7986C192.865 16.4979 193.764 19.495 194.463 20.7937C195.163 22.1923 197.561 23.2913 199.86 24.7898C202.558 26.588 201.459 30.0846 201.858 32.782L190.966 33.5812C189.767 31.8829 186.569 28.2864 188.967 26.0886C192.065 23.4911 187.568 23.3912 190.966 17.1972Z" fill="white"/>
        <path d="M234.735 54.061C233.336 56.4587 231.038 55.7593 229.739 55.3597C232.937 54.6604 232.037 51.6634 231.338 50.3646C230.638 48.966 228.34 47.867 225.942 46.2686C223.243 44.4704 224.343 40.8739 223.943 38.2764L234.835 37.5771C236.035 39.2755 239.132 42.872 236.734 45.0698C233.636 47.7672 238.133 47.967 234.735 54.061Z" fill="white"/>
        <path d="M311.582 9.50466C312.981 7.107 315.379 7.80633 316.679 8.20594C313.481 8.90525 314.38 11.9023 314.98 13.2011C315.679 14.5997 317.978 15.6986 320.376 17.397C323.074 19.1952 321.875 22.7917 322.175 25.3891L311.282 25.9885C310.083 24.2902 306.985 20.5938 309.484 18.4959C312.581 15.8984 308.084 15.5987 311.582 9.50466Z" fill="white"/>
        <path d="M354.552 47.2678C353.153 49.6655 350.755 48.9662 349.456 48.4667C352.654 47.8672 351.854 44.8702 351.155 43.4715C350.455 42.0729 348.157 40.8741 345.858 39.2756C343.16 37.4774 344.359 33.8809 344.059 31.2834L354.952 30.7839C356.151 32.5822 359.149 36.1787 356.751 38.2766C353.653 40.8741 358.15 41.1738 354.552 47.2678Z" fill="white"/>
        <path d="M432.398 4.30965C433.897 1.91199 436.195 2.71126 437.495 3.11087C434.297 3.71029 435.096 6.70736 435.696 8.10599C436.395 9.60453 438.694 10.7034 440.992 12.4018C443.59 14.2999 442.391 17.7965 442.691 20.394L431.798 20.6937C430.699 18.8954 427.601 15.199 430.1 13.201C433.198 10.6035 428.701 10.3038 432.398 4.30965Z" fill="white"/>
        <path d="M474.569 42.9718C473.07 45.3695 470.771 44.5703 469.472 44.0708C472.67 43.4714 471.871 40.4743 471.271 39.0757C470.572 37.5772 468.373 36.4782 466.075 34.7799C463.477 32.8817 464.776 29.3852 464.476 26.7877L475.368 26.488C476.467 28.2863 479.465 31.9826 476.967 33.9807C473.769 36.5781 478.166 36.9777 474.569 42.9718Z" fill="white"/>
        <path d="M553.314 1.71225C554.813 -0.685406 557.211 0.213717 558.411 0.713229C555.213 1.21274 555.912 4.20984 556.512 5.60848C557.211 7.10701 559.41 8.30581 561.708 10.0041C564.307 12.0022 562.907 15.3989 563.207 18.0963L552.315 18.1962C551.216 16.3979 548.318 12.6017 550.816 10.6036C554.014 8.10604 549.517 7.7064 553.314 1.71225Z" fill="white"/>
        <path d="M594.486 41.4733C592.987 43.871 590.688 42.9718 589.389 42.4723C592.587 41.9728 591.887 38.9758 591.288 37.5771C590.688 36.0786 588.39 34.8798 586.191 33.1814C583.593 31.1834 584.992 27.7867 584.792 25.0894H595.685C596.784 26.8876 599.682 30.6839 597.084 32.6819C593.886 35.0796 598.283 35.5791 594.486 41.4733Z" fill="white"/>
        <path d="M674.33 2.31175C675.929 0.0139956 678.227 0.913153 679.526 1.41266C676.329 1.81227 676.928 4.90921 677.528 6.30784C678.127 7.80638 680.326 9.0052 682.624 10.8034C685.122 12.8015 683.723 16.2981 683.823 18.8956L672.931 18.6958C671.932 16.8975 669.034 13.0013 671.632 11.1032C674.83 8.70555 670.333 8.10608 674.33 2.31175Z" fill="white"/>
        <path d="M714.402 43.1717C712.803 45.4695 710.505 44.5703 709.306 43.9709C712.504 43.5713 711.904 40.5743 711.404 39.0758C710.805 37.5772 708.606 36.2785 706.408 34.4802C703.91 32.4822 705.309 28.9856 705.209 26.3882L716.101 26.6879C717.1 28.4861 719.898 32.3823 717.3 34.2804C713.903 36.778 718.399 37.3774 714.402 43.1717Z" fill="white"/>
        <path d="M795.346 6.60764C797.045 4.3099 799.243 5.30891 800.542 5.90832C797.345 6.20802 797.844 9.30497 798.344 10.7036C798.943 12.2021 801.042 13.5009 803.24 15.399C805.739 17.4969 804.14 20.8936 804.24 23.491L793.347 22.8916C792.348 20.9935 789.65 17.0973 792.348 15.2991C795.746 13.0013 791.249 12.3021 795.346 6.60764Z" fill="white"/>
        <path d="M834.019 48.7664C832.32 50.9643 830.122 49.9653 828.923 49.3659C832.12 49.0661 831.621 46.0691 831.121 44.5705C830.621 43.072 828.423 41.6733 826.324 39.7752C823.926 37.6772 825.425 34.2806 825.425 31.6831L836.317 32.3824C837.217 34.2806 839.915 38.2767 837.217 40.0749C833.719 42.3727 838.216 43.1719 834.019 48.7664Z" fill="white"/>
        <path d="M916.362 15.3989C918.161 13.201 920.359 14.2999 921.558 14.8994C918.261 15.0992 918.66 18.1962 919.16 19.5948C919.66 21.0933 921.758 22.5918 923.857 24.49C926.255 26.6878 924.556 29.9846 924.456 32.682L913.564 31.5831C912.664 29.685 910.166 25.5889 912.864 23.8906C916.362 21.7926 911.965 20.8935 916.362 15.3989Z" fill="white"/>
        <path d="M953.036 59.2559C951.238 61.4537 949.039 60.2549 947.84 59.6555C951.038 59.5556 950.738 56.4586 950.238 54.9601C949.739 53.4615 947.74 51.963 945.641 49.965C943.343 47.7671 945.042 44.3704 945.142 41.7729L955.934 42.9718C956.834 44.8699 959.232 48.9659 956.534 50.6643C953.136 52.8621 957.533 53.8611 953.036 59.2559Z" fill="white"/>
        <path d="M1037.08 30.0846C1038.98 27.9866 1041.18 29.1854 1042.37 29.8848C1039.08 29.8848 1039.28 32.9817 1039.68 34.4803C1040.08 36.0787 1042.07 37.5773 1044.07 39.6752C1046.27 42.0729 1044.37 45.2698 1044.27 47.8672L1033.48 46.2688C1032.68 44.3706 1030.38 40.0748 1033.18 38.5763C1036.78 36.4783 1032.38 35.2795 1037.08 30.0846Z" fill="white"/>
        <path d="M1071.05 76.0395C1069.16 78.0375 1067.06 76.8387 1065.86 76.1394C1069.06 76.1394 1068.96 73.1423 1068.56 71.6438C1068.16 70.0453 1066.26 68.5468 1064.26 66.4488C1062.06 64.0512 1063.96 60.8543 1064.26 58.2568C1067.86 58.8562 1071.45 59.4557 1074.95 60.0551C1075.65 62.0532 1077.85 66.249 1075.05 67.7475C1071.55 69.5458 1075.85 70.8446 1071.05 76.0395Z" fill="white"/>
        <path d="M1156.89 52.7623C1158.99 50.8641 1160.99 52.2628 1162.19 52.9621C1158.89 52.6624 1158.89 55.7593 1159.19 57.2579C1159.49 58.8563 1161.39 60.5547 1163.19 62.7525C1165.29 65.2501 1163.09 68.3471 1162.69 70.9446L1152.1 68.447C1151.5 66.449 1149.5 62.0532 1152.4 60.7545C1156.1 59.0561 1151.8 57.5576 1156.89 52.7623Z" fill="white"/>
        <path d="M1186.97 101.315C1184.88 103.213 1182.98 101.814 1181.88 101.015C1185.08 101.315 1185.18 98.2177 1184.98 96.7192C1184.78 95.1207 1182.98 93.4224 1181.18 91.1246C1179.18 88.6271 1181.38 85.5301 1181.88 82.9326C1185.37 83.8317 1188.87 84.7309 1192.37 85.7299C1192.97 87.7279 1194.67 92.2236 1191.77 93.4224C1188.07 95.0208 1192.17 96.6193 1186.97 101.315Z" fill="white"/>
        <path d="M1274.81 87.1289C1277.11 85.4306 1279.01 87.029 1280.11 88.0281C1276.81 87.3287 1276.41 90.4257 1276.51 91.9242C1276.61 93.5227 1278.31 95.5207 1279.81 97.9184C1281.51 100.716 1279.01 103.413 1278.31 106.01L1273.21 104.112L1268.12 102.214C1267.72 100.116 1266.22 95.6206 1269.32 94.6216C1273.21 93.3229 1269.12 91.3248 1274.81 87.1289Z" fill="white"/>
        <path d="M1298.2 139.278C1295.9 140.876 1294.2 139.178 1293.2 138.279C1296.2 139.078 1296.8 135.981 1296.8 134.482C1296.8 132.884 1295.3 130.886 1293.9 128.488C1292.3 125.691 1294.9 122.994 1295.7 120.496L1305.69 124.692C1305.99 126.79 1307.09 131.386 1303.99 132.185C1300.19 133.184 1303.99 135.282 1298.2 139.278Z" fill="white"/>
        <path d="M1387.13 140.676C1389.73 139.577 1391.33 141.576 1392.23 142.774C1389.13 141.376 1388.13 144.273 1387.83 145.871C1387.63 147.47 1388.93 149.668 1389.93 152.465C1391.03 155.562 1387.93 157.66 1386.73 160.057C1383.74 158.059 1380.54 156.061 1377.44 154.163C1377.54 152.065 1376.94 147.27 1380.24 146.97C1384.34 146.371 1380.64 143.474 1387.13 140.676Z" fill="white"/>
        <path d="M1397.73 196.722C1395.13 197.721 1393.93 195.723 1393.33 194.524C1396.03 195.922 1397.43 193.225 1397.83 191.726C1398.23 190.228 1397.33 187.93 1396.63 185.233C1395.83 182.136 1398.93 180.138 1400.33 177.94L1408.82 184.533C1408.52 186.631 1408.32 191.327 1405.12 191.327C1401.22 191.327 1404.22 194.324 1397.73 196.722Z" fill="white"/>
      </svg>

      <svg :class="'step' + currentStep" class="ronce8BD ronce" viewBox="0 0 468 489" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.311 561.929C20.311 561.929 111.248 14.4639 534.953 20.458" stroke="white" stroke-width="20" stroke-miterlimit="10"/>
        <path d="M35.3007 429.158C33.6019 426.861 35.3007 424.763 36.2001 423.664C35.5006 427.061 38.5985 427.36 40.1974 427.26C41.7963 427.061 43.7949 425.262 46.1932 423.564C48.9913 421.666 51.7893 424.163 54.2876 424.863L50.4902 435.752C48.3917 436.252 43.7948 437.95 42.7955 434.753C41.5964 430.557 39.5977 434.953 35.3007 429.158Z" fill="white"/>
        <path d="M87.6644 402.185C89.2633 404.483 87.5645 406.381 86.6651 407.48C87.3646 404.183 84.3667 403.783 82.7678 403.883C81.1689 403.983 79.1703 405.681 76.672 407.28C73.874 409.078 71.1758 406.481 68.5776 405.781C69.8767 402.185 71.2758 398.688 72.6748 395.092C74.7733 394.692 79.3701 393.294 80.2695 396.49C81.4687 400.487 83.6672 396.39 87.6644 402.185Z" fill="white"/>
        <path d="M85.366 309.376C83.9669 306.878 85.8656 304.98 86.9648 303.981C85.8656 307.278 88.8635 307.977 90.3625 308.077C91.9613 308.077 94.1598 306.578 96.8579 305.18C99.8558 303.681 102.254 306.578 104.752 307.577L102.054 312.672L99.4561 317.867C97.3576 318.067 92.5609 319.166 91.9613 315.869C91.4617 311.673 88.8635 315.769 85.366 309.376Z" fill="white"/>
        <path d="M141.027 290.194C142.326 292.692 140.328 294.39 139.328 295.289C140.527 292.192 137.53 291.293 136.031 291.193C134.432 290.994 132.233 292.392 129.535 293.591C126.537 294.99 124.139 291.993 121.741 290.994L127.437 281.003C129.535 280.903 134.332 280.204 134.732 283.501C135.131 287.497 137.829 283.801 141.027 290.194Z" fill="white"/>
        <path d="M153.619 198.184C152.719 195.487 155.018 193.988 156.217 193.089C154.518 196.186 157.316 197.385 158.915 197.685C160.514 197.985 162.912 196.886 165.81 195.987C169.008 194.988 170.907 198.284 173.105 199.683L166.11 208.874C164.011 208.674 159.015 208.874 159.115 205.577C159.115 201.381 155.817 204.978 153.619 198.184Z" fill="white"/>
        <path d="M211.778 189.493C212.478 192.19 210.379 193.389 209.18 194.088C210.879 191.291 208.181 189.792 206.682 189.393C205.183 188.893 202.784 189.892 199.886 190.592C196.689 191.391 194.89 188.094 192.791 186.496L196.489 182.1C197.688 180.601 198.887 179.203 200.186 177.804C202.285 178.104 207.181 178.404 206.882 181.7C206.482 185.697 209.979 182.6 211.778 189.493Z" fill="white"/>
        <path d="M243.856 102.378C243.656 99.4805 246.154 98.5814 247.553 97.9819C245.155 100.579 247.653 102.478 249.052 103.177C250.551 103.876 253.149 103.277 256.147 103.077C259.545 102.877 260.544 106.574 262.343 108.472C259.345 110.869 256.347 113.367 253.349 115.765C251.35 115.065 246.454 114.166 247.353 110.869C248.452 106.873 244.355 109.571 243.856 102.378Z" fill="white"/>
        <path d="M302.315 108.272C302.315 109.671 301.716 110.47 300.916 111.069C300.117 111.669 299.217 111.869 298.618 112.068C300.916 109.771 298.718 107.673 297.419 106.873C296.02 106.074 293.521 106.374 290.623 106.274C287.326 106.174 286.426 102.578 284.727 100.58C287.825 98.3817 290.923 96.084 294.121 94.0859C296.119 94.9851 300.716 96.4836 299.517 99.5806C298.218 103.277 302.215 101.179 302.315 108.272Z" fill="white"/>
        <path d="M357.177 33.5452C357.876 30.7479 360.575 30.5481 362.074 30.4482C358.976 32.2465 360.775 34.7441 361.974 35.843C363.173 36.9419 365.871 37.1417 368.769 37.841C372.067 38.6403 371.967 42.4365 373.066 44.8342L362.273 49.2299C360.575 48.031 356.178 45.6334 357.976 42.8361C360.275 39.1398 355.478 40.5383 357.177 33.5452Z" fill="white"/>
        <path d="M411.239 56.6226C410.44 59.32 408.042 59.2201 406.643 59.1202C409.541 57.7216 408.042 55.0242 407.042 53.8254C406.043 52.6266 403.445 52.127 400.747 51.128C397.649 50.0291 397.949 46.3327 396.849 43.8352L407.742 40.4385C409.341 41.8371 413.238 44.7343 411.239 47.3318C408.741 50.7284 413.238 49.8293 411.239 56.6226Z" fill="white"/>
      </svg>


      <svg :class="{ visible: currentStep === 2 }" class="mainHG" viewBox="0 0 430 797" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-252.884 323.776C-252.884 323.776 -160.775 476.809 -75.0284 536.533C10.7182 596.258 229.228 694.24 229.228 694.24C229.228 694.24 251.575 703.117 259.563 690.276C267.263 677.862 260.2 668.532 249.781 663.478C237.746 657.647 180.056 629.062 180.056 629.062C180.056 629.062 171.474 626.228 177.264 615.176C181.66 606.81 188.576 610.77 188.576 610.77L292.557 665.358C292.557 665.358 313.56 674.131 322.786 662.714C332.013 651.296 326.23 639.917 318.58 635.033C310.931 630.148 206.52 579.643 206.52 579.643C206.52 579.643 198.662 576.189 203.588 567.114C208.515 558.038 217.486 564.308 217.486 564.308L335.405 622.312C335.405 622.312 354.669 635.004 364.533 616.84C369.46 607.765 368.741 600.879 357.829 593.734C343.585 584.425 234.098 531.832 234.098 531.832C234.098 531.832 221.012 526.474 225.764 518.792C230.516 511.11 235.605 515.604 239.534 517.331C243.463 519.057 340.797 564.218 340.797 564.218C342.42 565.023 344.188 565.493 345.996 565.602C347.805 565.711 349.616 565.455 351.324 564.851C353.032 564.247 354.601 563.306 355.939 562.084C357.276 560.862 358.355 559.384 359.111 557.738C364.049 548.65 361.975 541.649 356.208 537.703C347.66 531.971 241.436 482.083 235.239 476.102C229.041 470.121 212.173 460.315 211.327 454.798C210.482 449.281 212.452 442.628 219.209 443.253C225.967 443.879 289.3 451.182 289.3 451.182C289.3 451.182 297.657 449.222 298.921 435.718C300.186 422.215 289.359 421.195 289.359 421.195C289.359 421.195 214.1 395.142 195.299 392.025C176.498 388.908 142.096 392.549 121.245 382.43C100.394 372.312 42.5785 334.238 31.5445 320.956C20.5106 307.673 -13.2065 274.547 -46.4572 192.426C-79.7079 110.305 -161.272 34.5722 -207.648 64.3399C-254.025 94.1077 -353.621 197.907 -252.884 323.776Z" fill="#B62220"/>
      </svg>

      <svg :class="{ visible: currentStep === 2 }" class="mainHD" viewBox="0 0 471 609" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M801.531 320.996C801.531 320.996 727.298 367.05 651.329 384.232C575.36 401.414 531.063 403.114 470.788 425.472C410.582 447.995 236.735 559.583 236.735 559.583C236.735 559.583 216.716 574.16 203.621 554.965C190.36 535.838 214.128 523.787 214.128 523.787L277.907 488.2C277.907 488.2 286.401 483.368 283.13 477.747C280.228 472.554 277.893 467.323 265.887 474.945C253.216 482.84 158.253 536.995 158.253 536.995C158.253 536.995 144.532 547.06 131.372 532.919C118.115 519.011 131.242 505.129 133.795 503.31C140.16 498.964 246.245 439.288 246.245 439.288C246.245 439.288 257.428 432.969 254.323 427.28C251.051 421.658 248.262 418.16 236.256 425.783C224.25 433.405 103.304 502.259 103.304 502.259C103.304 502.259 85.2345 514.491 72.8038 495.024C60.373 475.557 85.3784 464.159 85.3784 464.159L218.329 387.683C218.329 387.683 226.824 382.851 224.692 378.117C222.725 373.315 219.044 370.955 208.753 376.135C200.055 380.469 131.382 421.154 109.651 433.918C100.129 439.557 87.9264 438.172 80.34 430.064C80.0383 429.8 79.6688 429.372 79.5332 429.04C69.9569 417.492 78.0341 405.484 82.3693 401.389C86.7724 397.459 217.547 324.195 228.875 314.916C240.468 305.336 248.562 300.475 248.545 293.328C248.541 288.109 244.616 288.942 237.423 289.374C230.163 289.639 169.216 292.356 169.216 292.356C169.216 292.356 148.295 292.414 146.731 273.911C145.168 255.409 161.994 251.034 171.218 250.351C180.373 249.503 298.432 234.231 309.615 227.913C320.797 221.594 448.889 183.459 466.952 179.737C485.084 176.181 657.78 48.0478 717.124 68.4167C776.302 88.8536 944.309 183.262 801.531 320.996Z" fill="#B62220"/>
      </svg>

      <svg :class="{ visible: currentStep === 2 }" class="mainBG" viewBox="0 0 475 352" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M285.428 351.996H0V260.776C6.38371 257.482 12.5409 254.348 18.5247 251.303C51.645 234.449 79.4551 220.296 110.976 196.471C159.703 159.427 287.637 10.0122 287.637 10.0122C287.637 10.0122 301.896 -8.76838 319.099 5.14513C336.435 18.9511 318.066 36.4565 318.066 36.4565L269.663 86.3925C269.663 86.3925 263.242 93.1268 267.766 97.3562C267.904 97.4892 268.04 97.6225 268.177 97.7554C272.083 101.558 275.699 105.08 284.258 95.1834C293.648 84.511 365.396 9.0938 365.396 9.0938C365.396 9.0938 375.149 -3.84583 391.025 5.44182C406.927 14.4895 398.762 30.7476 396.933 33.1055C392.32 38.8141 311.944 122.314 311.944 122.314C311.944 122.314 303.479 131.141 307.87 135.478C312.395 139.708 315.898 142.133 324.756 131.891C333.614 121.648 425.025 25.7056 425.025 25.7056C425.025 25.7056 438.149 9.59752 454.821 23.9412C471.493 38.2849 451.816 55.5335 451.816 55.5335L351.547 161.718C351.547 161.718 345.126 168.453 348.368 172.185C351.478 176.025 355.482 177.168 363.446 169.623C368.857 164.538 403.069 127.519 424.806 103.999C430.156 98.2103 434.75 93.239 437.973 89.7632C445.11 82.0113 456.617 79.9285 465.758 85.2428C466.105 85.4001 466.56 85.6898 466.774 85.9547C478.672 93.8596 474.599 107.024 471.768 111.947C470.548 113.938 452.707 132.832 431.712 155.067C402.189 186.333 366.43 224.204 361.879 230.964C360.441 233.122 359.064 235.107 357.786 236.95C352.007 245.283 348.244 250.709 349.862 256.042C351.156 260.335 354.01 259.26 359.288 257.271C359.859 257.056 360.458 256.83 361.087 256.599C367.635 254.366 422.466 235.18 422.466 235.18C422.466 235.18 441.528 229.392 448.026 245.835C454.524 262.279 440.38 270.881 432.157 274.032C424.04 277.315 320.572 323.607 312.107 332.434C309.697 334.948 299.351 342.412 285.428 351.996Z" fill="#B62220"/>
      </svg>


      <svg :class="{ visible: currentStep === 2 }" class="mainBD" viewBox="0 0 451 394" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M516.43 455.699H266.071C262.88 447.609 259.983 441.198 257.734 436.221C256.639 433.799 255.698 431.716 254.951 429.945C248.132 413.772 203.113 359.695 185.812 343.707C174.375 333.137 155.405 326.672 138.842 321.027C130.349 318.132 122.489 315.453 116.601 312.547C99.2305 303.973 33.6991 256.448 33.6991 256.448C33.6991 256.448 23.4673 252.265 28.6776 239.502C33.888 226.739 42.595 227.296 42.595 227.296C42.595 227.296 102.052 253.067 108.441 255.667C114.83 258.267 118.708 252.375 119.512 246.76C120.034 243.106 114.496 235.688 109.386 228.846C106.642 225.17 104.021 221.66 102.53 218.989C100.056 214.555 69.2061 185.839 42.4338 160.918C23.0441 142.87 5.79336 126.812 3.01005 123.412C-1.4362 117.873 -1.39054 110.452 6.09195 103.067C7.31263 101.688 8.79759 100.569 10.4589 99.7744C12.1202 98.9801 13.924 98.5273 15.7634 98.4429C17.6028 98.3584 19.4405 98.644 21.1675 99.2827C22.8945 99.9214 24.4757 100.9 25.8174 102.161C25.8174 102.161 107.191 174.78 110.504 177.618C111.01 178.051 111.522 178.554 112.053 179.075C115 181.969 118.494 185.4 124.33 180.255C131.218 174.183 120.067 165.114 120.067 165.114C120.067 165.114 29.0612 81.6838 17.9486 68.43C9.43946 58.2627 10.7702 51.3533 18.2378 43.9771C33.1878 29.2161 48.1864 47.2395 48.1864 47.2395L145.799 138.419C145.799 138.419 152.678 147.162 160.145 139.786C167.613 132.41 160.986 126.734 160.986 126.734C160.986 126.734 74.3041 46.8308 68.3024 39.8249C62.3008 32.8191 60.0295 20.0453 72.3701 11.6583C84.7107 3.27124 102.556 17.9963 102.556 17.9963L187.617 101.744C187.617 101.744 193.177 107.635 199.92 100.793C208.81 91.7485 201.297 86.4623 201.297 86.4623C201.297 86.4623 153.602 41.6524 143.614 32.4327C134.968 24.4457 130.847 13.2877 141.997 3.48202C153.552 -6.655 172.674 8.56704 172.674 8.56704C172.674 8.56704 356.357 168.283 422.166 251.654C464.419 305.181 497.885 396.897 516.43 455.699Z" fill="#B62220"/>
      </svg>


    </div>
  </article>
</template>

<script>
import sequence from "@/mixins/sequenceMixin";
import ScrollIncitator from "@/components/lib/ScrollIncitator";

export default {
  name: 'TransitionSentenceSequence',
  components: {ScrollIncitator},
  mixins: [ sequence ],
  data: () => ({
    displayNextSlide: false,
    scrollFactor: 10,
    translateY: 0,
    currentStep: 0,
    maxStep: 2,
    isTransitioning: false,
    ronceNum: 8,
    animationEndCount: 0,
    delayBeforeNextSlide: 3200,
    displayScrollIncite: false
  }),
  props: {
    currentState: {
      type: String,
      default: 'future'
    },
    isInertia: Boolean
  },
  mounted () {
    this.$refs.box.addEventListener('animationend', this.boxAnimationEnd)
    this.$refs.box.addEventListener('transitionend', this.titleTransitionEnd)
  },
  watch: {
    currentState (newVal) {
      if (newVal === 'current') {
        this.isTransitioning = true
        this.currentStep = 0
      }
    }
  },
  methods: {
    onScroll () {
      const progress = this.$el.scrollTop / (this.lottieScrollHeight - this.animationEndedScrollOffset)
      this.displayScrollIncite = false
      if (!this.animationFinished ) {
        if (progress < 1) {
          this.handleScrollLottie(progress)
        } else {
          this.animationFinished = true
          this.$el.scrollTop = 0
        }
      }
    },
    onWheel (e) {
      if (!this.isTransitioning && !this.isInertia) {
        if (e.deltaY > 0) {
          if (this.currentStep < this.maxStep) {
            this.currentStep++
            this.isTransitioning = true
            this.$emit('start-inertia')
            if (this.currentStep >= this.maxStep) {
              setTimeout(() => {
                this.$emit('next-slide', false)
              }, this.delayBeforeNextSlide)
            }
          }
        } else if (e.deltaY < 0 && this.currentStep === 0) {
          this.$emit('prev-slide')
        }
      }
    },
    boxAnimationEnd (e) {
      if (e.target.classList.contains('ronce')) {
        this.animationEndCount++
        if (this.animationEndCount >= this.ronceNum) {
          this.isTransitioning = false
          this.animationEndCount = 0
        }
      }
    },
    titleTransitionEnd (e) {
      if (e.target.classList.contains('second-title')) {
        this.isTransitioning = false
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.css-animation-sequence {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  padding: 4% 10%;
  background: #5C90B6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  transition: all $slideDurationEasing;
  &.future {
    opacity: 0;
  }
  &.arriving-forward {
    animation: fade-in $slideDurationEasing $slideDuration both;
  }
  &.past {
    opacity: 0;
  }

  h1 {
    font-family: $titleFont;
    font-size: $titleSize;
    color: #ffff;
    margin: auto auto;
    width: 100%;
    text-align: center;
    padding: 0 20%;
    top: 40%;
    position: absolute;
    transition: all ease-in 0.3s;
    opacity:0;

    &.visible {
      opacity: 1;
      transition-delay: .3s;
    }


    .scroll-incite {
      display: flex;
      margin: 30px auto 0 auto;
      opacity: 0;
      transition-delay: 1s;

      transition: all ease-in-out .3s;
      &.visible {
        opacity:1;
        transition-delay: 1s;

      }
      .incite-wrapper {
        margin: auto;
      }
    }




    //&.first-title{
    //  position: absolute;
    //  animation-name:  appritionTitle;
    //  animation-duration: 6s;
    //  animation-direction: alternate;
    //}
    //
    //&.second-title{
    //  position: absolute;
    //  animation-name:  appritionTitle;
    //  animation-direction: alternate;
    //  animation-duration: 6s;
    //  animation-fill-mode: forwards;
    //  animation-delay: 4s;
    //}
  }

  .box {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
  }


  /* mains */
  .mainHG, .mainHD, .mainBG, .mainBD {
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.47,.03,.25,1.01);
    animation-duration: 4s;
  }

  .mainHG {
    position: absolute;
    top: -100%;
    left: -100%;
    width: 50%;
    height: 80%;
    animation-delay: .6s;
    &.visible {
      animation-name: anim-mainHG;
    }
  }

  .mainHD {
    position: absolute;
    top: -100%;
    right: -100%;
    width: 50%;
    height: 60%;
    animation-delay: 0s;
    &.visible {
      animation-name: anim-mainHD;
    }
  }

  .mainBG {
    position: absolute;
    bottom: -100%;
    left: -100%;
    width: 40%;
    height: 40%;
    animation-delay: .3s;
    &.visible {
      animation-name: anim-mainBG;
    }
  }

  .mainBD {
    position: absolute;
    bottom: -100%;
    right: -100%;
    width: 40%;
    height: 40%;
    animation-delay: .9s;
    &.visible {
      animation-name: anim-mainBD;
    }
  }

  /* ronce */
  $ronceDelay: 2s;
  .ronce1HG, .ronce2HD, .ronce3HD, .ronce4HG, .ronce5HG, .ronce6BG, .ronce7BG, .ronce8BD {
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.47,.03,.25,1.01);
    animation-direction: reverse;
    animation-duration: 3s;
  }


  .ronce1HG  {
    position: absolute;
    top: -10%;
    left: 0%;
    width: 100%;
    animation-delay: 0.2s;
    &.step0, &.step2 {
      animation-name: ronceHG1;
    }
    &.step2 {
      animation-delay: 0.2s + $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce2HD {
    position: absolute;
    top: 0%;
    right: -35%;
    height: 100%;
    width: 80%;
    animation-delay: 0.1s;
    &.step0, &.step2 {
      animation-name: ronceHG2;
    }
    &.step2 {
      animation-delay: 0.1s + $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce3HD  {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 50%;
    width: 50%;
    &.step0, &.step2 {
      animation-name: ronceBG3;
    }
    &.step2 {
      animation-delay: $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce4HG {
    position: absolute;
    top: 0%;
    left: -10%;
    height: 50%;
    width: 50%;
    animation-delay: 0.8s;
    &.step0, &.step2 {
      animation-name: ronceHG4;
    }
    &.step2 {
      animation-delay: 0.8s + $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce5HG  {
    position: absolute;
    bottom: 0%;
    left: -20%;
    height: 80%;
    width: 50%;
    animation-delay: 0.3s;
    &.step0, &.step2 {
      animation-name: ronceHG5;
    }
    &.step2 {
      animation-delay: 0.3s + $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce6BG {
    position: absolute;
    bottom: 0%;
    left: -10%;
    height: 50%;
    width: 50%;
    animation-delay: 0.6s;
    &.step0, &.step2 {
      animation-name: ronceBG6;
    }
    &.step2 {
      animation-delay: 0.6s + $ronceDelay;
      animation-direction: normal;
    }
  }

  .ronce7BG {
    position: absolute;
    bottom: -10%;
    left: 0%;
    width: 100%;
    animation-delay: 0.8s;
    &.step0, &.step2 {
      animation-name: ronceBG7;
    }
    &.step2 {
      animation-delay: 0.8s + $ronceDelay;
      animation-direction: normal;
    }

  }

  .ronce8BD {
    position: absolute;
    bottom: 0%;
    right: -10%;
    height: 50%;
    width: 50%;
    animation-delay: 0.5s;
    &.step0, &.step2 {
      animation-name: ronceBG8;
    }
    &.step2 {
      animation-delay: 0.5s + $ronceDelay;
      animation-direction: normal;
    }
  }
}



/*__________________ANIMATIONS_____________________________*/

/*____________ Animations des ronce ____________*/


@keyframes ronceHG1 {
  from {
    top: -10%;
    left: 0%;
  }
  to {
    top: -100%;
    left: -100%;

  }
}

@keyframes ronceHG2 {
  from {
    top: 0%;
    right: -35%;
  }
  to {
    top: -100%;
    right: -100%;

  }
}


@keyframes ronceBG3 {
  from {
    top: 0%;
    right: 0%;
  }
  to {
    top: -100%;
    right: -100%;

  }
}

@keyframes ronceHG4 {
  from {
    top: 0%;
    left: -10%;
  }
  to {
    top: -100%;
    left: -100%;

  }
}

@keyframes ronceHG5 {
  from {
    bottom: 0%;
    left: -20%;
  }
  to {
    bottom: -100%;
    left: -100%;

  }
}


@keyframes ronceBG6 {
  from {
    bottom: 0%;
    left: -10%;
  }
  to {
    bottom: -100%;
    left: -100%;

  }
}

@keyframes ronceBG7 {
  from {
    bottom: -10%;
    left: 0%;
  }
  to {
    bottom: -100%;
    left: -100%;

  }
}


@keyframes ronceBG8 {
  from {
    bottom: 0%;
    right: -10%;
  }
  to {
    bottom: -100%;
    right: -100%;

  }
}

/*____________ Animations des ronce ____________*/


@keyframes anim-mainHG {
  100% {
    top: -100%;
    left: -100%;
  }
  50% {
    top: -28%;
    left: -10%;
  }
  0% {
    top: -100%;
    left: -100%;
  }
}

@keyframes anim-mainHD {
  100% {
    top: -100%;
    right: -100%;
  }
  50% {
    top: -15%;
    right: -10%;

  }
  0% {
    top: -100%;
    right: -100%;

  }
}

@keyframes anim-mainBD {
  100% {
    bottom: -100%;
    right: -100%;
  }
  50% {
    bottom: 0%;
    right: -10%;
  }
  0% {
    bottom: -100%;
    right: -100%;

  }
}

@keyframes anim-mainBG {
  100% {
    bottom: -100%;
    left: -100%;
  }
  50% {
    bottom: 0%;
    left: -10%;
  }
  0% {
    bottom: -100%;
    left: -100%;

  }
}

</style>
